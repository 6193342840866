.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    margin: 50px auto;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: grey;
  }
  
  .paginationActive a {
    color: white;
    background-color: grey;
  }
  
  .paginationDisabled a {
    color: white;
    background-color: grey;
  }
